import VueJwtDecode from "vue-jwt-decode";

export default {
    name: 'UserProfileRedirect',
    render (h) {
        const div = h('div')
        console.log(div, {h})
    },
    beforeMount () {
        const { query: { token, from } } = this.$route
        if (token) {
            let decodedToken = VueJwtDecode.decode(token)
            sessionStorage.setItem('userData', JSON.stringify(decodedToken))
            console.log(token)
            sessionStorage.setItem('userToken', token)
            sessionStorage.setItem('learnerRoute', from)
            // just tries to wait for the token to sit in, hence the timeout
            let updateQuery = {...this.$route.query}
            delete updateQuery.token
            setTimeout(() => {
                this.$router.push({
                    name: updateQuery.to,
                    query:updateQuery
                })
            }, 250)
        }
    }
}